.AppHeader {
    padding: 0;
    box-shadow: unset !important;
    position: absolute !important;
    width: fit-content !important;
    top: 20px !important;
    right: 20px !important;
}
#root{
    min-height: 100vh ;
    position: relative;
    height: fit-content !important;
    padding-bottom: 85px;
}
.AppHeader .css-qr05wp{
    box-shadow: unset !important;
}
.AppHeader .MuiToolbar-root{
    min-height: unset;
    justify-content: flex-end;
}
.AppHeader button{
    padding: 6px 0 !important;
}
.AppHeader button .MuiTypography-button{
    display: flex;
    align-content: center;
    justify-content: center;
}
.AppHeader .nav-open span{
    color: #29106b;
}
.body{
    height: fit-content !important;
}

.page-content-wrapper{
    padding: 40px 0;
    padding-bottom: 20px;
    min-height: calc(100vh - 140px);
}
.page-content-wrapper .page-head{
    margin-bottom: 40px;
}
.page-content-wrapper .content .MuiStepper-root{
    max-width: 792px;
    margin: 0 auto;
}
.page-content-wrapper .content .MuiStepper-root .MuiStepLabel-label.Mui-disabled span, .page-content-wrapper .content .MuiStepper-root .MuiStepLabel-label.Mui-completed span{
    color: #ccc !important;
}
.content .home-logo{
    padding-top: 15px !important;
}
.badge{
    position: absolute;
    width: 20px;
    height: 20px;
    font-size: 12px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -10px;
    right: -10px;
    background: #7040f1;
    border-radius: 50%;
    color: #fff;
}
.flex{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.input-text-field{
    width: 100%;
}
.input-text-field input{
    font-size: 16px !important;
    color: #1f1f1f !important;
    font-weight: 600 !important;
    font-family: Public Sans,sans-serif !important;
}
a{
    text-decoration: none;
}
h2, h3, h4, p, span{
    display: block;
    text-align: center;
}
p{
    letter-spacing: -0.2px;
}
.MuiContainer-root{
    padding-left: 30px !important;
    padding-right: 30px !important;
}
.css-wd5w37-MuiContainer-root{
    justify-content: space-between;
}
.select-field {
    width: 360px;
    margin: 0 auto;
    display: flex !important;
    font-size: 16px !important;
    font-weight: 600 !important;
}
.select-field .css-13cymwt-control, .select-field .css-t3ipsp-control{
    width: 100%;
    border-color: #fff;
    text-align: left;
    padding: 12px 4px;
    background: transparent !important;
}
.select-field .css-1jqq78o-placeholder, .select-field .css-1dimb5e-singleValue, .select-field .css-166bipr-Input, .select-field .css-1cfo1cf{
    color: #fff !important;
}
.icon-btn{
    text-transform: unset !important;
    padding: 0 !important;
}
.icon-btn:hover{
    background: unset !important;
}
.icon-btn.less span{
    padding-bottom: 8px;
}
.icon-btn span{
    margin-right: 16px;
    font-size: 44px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: #fff;
    font-weight: 500;
}
.icon-btn-outlined{
    text-transform: none !important;
    border: 1px solid #ccc !important;
    margin-right: 16px !important;
    padding: 10px !important;
}
.icon-btn-outlined svg{
    margin-left: 10px;
    color: #637480;
    font-size: 30px;
}
.icon-text{
    display: flex;
    align-items: center;
    text-align: left;
    line-height: 1.3 !important;
}
.icon-text span{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #7040f1;
    color: #fff;
}
.icon-text span svg{
    color: #fff;
    font-size: 24px;
}
.close-btn{
    position: absolute !important;
    top: 26px;
    right: 0px;
}
.close-btn svg{
    font-size: 30px;
}
.app-modal{
    position: fixed;
    transition: 0.4s ease-in-out;
    overflow: hidden;
}
.app-modal::-webkit-scrollbar{
    width: 5px;
}
.app-modal::-webkit-scrollbar-thumb{
    background-color: rgba(255,255,255,0.4);
    border-radius: 3px;
}
.app-modal.sidebar-nav{
    top: 0;
    right: 0;
    height: 0px;
    overflow: hidden;
    width: 0;
    height: 100%;
    opacity: 0;
    max-width: 400px;
}
.app-modal.sidebar-nav.open{
    overflow-y: auto;
    width: 100%;
    opacity: 1;
    box-shadow: -6px 0px 12px rgba(0,0,0,0.1);
}
.sidebar-nav .sidebar-wrapper{
    padding: 0 30px !important;
    padding-top: 60px !important;
}
.sidebar-nav span, .sidebar-nav p, .sidebar-nav b, .sidebar-nav a{
    color: #fff !important;
    text-align: center;
}
.sidebar-nav .sidebar-wrapper .clg-logo{
    margin: 0 auto;
    max-height: 120px;
}
.sidebar-nav .clg-info-wrapper{
    margin: 30px 0;
    padding: 30px 0;
    border-top: 1px solid rgba(255,255,255,0.1);
    border-bottom: 1px solid rgba(255,255,255,0.1);
}
.sidebar-nav .clg-info{
    margin-bottom: 10px;
}
.sidebar-nav .nav-links{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid rgba(255,255,255,0.1);
}
.sidebar-nav .nav-links a{
    margin-bottom: 10px;
    width: fit-content;
}
.sidebar-nav .close-btn {
    top: 16px !important;
    right: 10px !important;
}
.close-btn:hover span{
    color: #fff !important;
}
.sidebar-nav .close-btn svg{
    font-size: 36px;
}
.sidebar-nav .nav-footer .logo{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    margin-right: 0 !important;
}
.sidebar-nav .nav-footer .logo img{
    width: 54%;
    margin-left: 20px;
}
.tc-checkbox-wrapper p{
    display: inline-flex;
    align-items: center;
}
.tc-checkbox-wrapper a{
    text-decoration: underline !important;
    margin-left: 4px;
}
.app-popup{
    background: rgba(0,0,0,0.7) !important;
}
.app-popup .MuiPopover-paper{
    position: fixed;

}
.app-popup.skilldar .MuiPopover-paper{
    top: 50% !important;
    left: 25% !important;
    transform: translateY(-50%) !important;
    width: 50%;
    padding: 30px 40px;
}
.app-popup .popup-head{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.app-popup .popup-head.compare{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px  ;
}
.app-popup .slider-content-wrapper{
    align-items: center;
    padding: 20px 0;
}
.app-popup.skilldar .slider-content-wrapper{
    min-height: 515px;
}
.app-popup .slider-content-wrapper .content-col.left-col{
    padding-right: 10px;
    display: flex;
    justify-content: center;
}
.app-popup .slider-content-wrapper .content-col.right-col{
    display: flex;
    flex-direction: column;
    height: 300px;
}
.app-popup .slider-content-wrapper .content-col.right-col .bottom-spacing{
    margin-bottom: 60px;
}
.app-popup .slider-content-wrapper .content-col p, .app-popup .slider-content-wrapper .content-col span{
    text-align: left;
}
.app-popup .slider-content-wrapper .content-col p:first-of-type, .app-popup .slider-content-wrapper .content-col span:first-of-type{
    margin-bottom: 20px;
}
.app-popup .popup-slider-control{
    display: flex;
    align-content: center;
    justify-content: center;
}
.app-popup .popup-slider-control button.disable{
    height: 0;
    overflow: hidden;
    padding: 0;
}
.app-popup .popup-slider-control button.disable svg{
    opacity: 0;
}
.app-popup .popup-slider-control button svg{
    font-size: 32px;
}
.app-popup .popup-slider-control .dots-wrapper{
    display: flex;
    align-items: center;
}
.app-popup .popup-slider-control .dots-wrapper .dot{
    background: #ccc;
    width: 14px;
    margin: 0 5px;
    border-radius: 50%;
    height: 14px;
}
.app-popup .popup-slider-control .dots-wrapper .dot.active{
    background: #7040f1;
}
.logo-wrapper{
    display: flex;
    align-items: center;
}
.logo{
    display: block;
    margin-right: 30px !important;
}
.logo img{
    max-width: 240px;
    width: 100%;
}
.bradford-logo-wrapper{
    padding-left: 30px;
    border-left: 2px solid #1f1f1f;
}
.bradford-logo-wrapper p{
    text-align: left !important;
}
.bradford-logo-wrapper img{
    margin-top: 12px;
    max-width: 320px;
}
.btn{
    padding: 12px 30px !important;
    border-radius: 8px;
    display: block;
    text-decoration: none;
    width: fit-content;
    margin: 0 auto;
    text-transform: uppercase;
    transition: 0.2s ease-in-out;
}
.btn:hover{
    box-shadow: 0px 0px 12px rgba(0,0,0,0.2) !important;
}
.btn.main{
    background-color: #7040f1 !important;
}
.big-btn{
    padding: 16px 40px !important;
}
.AppFooter{
    position: absolute;
    bottom: 20px;
    right: 10px;
}
.AppFooter .logo{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.AppFooter .logo img{
    max-width: 140px;
    margin-left: 20px;
}
.page-content-wrapper.home{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 200px);
    width: 100%;
}

.page-content-wrapper.home .content > div{
    text-align: center;
}
.page-content-wrapper.home .link-wrapper{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
}
.page-content-wrapper.home .link-wrapper{
    margin: 24px 0;
}
.page-content-wrapper.home .link-wrapper p{
    font-size: 60px;
    font-weight: 800;
    margin: 0 10px;
}
.page-content-wrapper.home .upskill, .page-content-wrapper.home .reskill{
    text-align: center;
}
.page-content-wrapper.home .content .entry-text{
    margin-bottom: 20px;
}
.page-content-wrapper.home .content img{
    margin: 0 auto;
    margin-bottom: 40px;
    max-width: 440px;
    height: auto;
}
.MuiBox-root .css-1yf34q3{
    display: none;
}
.page-content-wrapper .content-wrapper{
    margin-top: 30px;
}
.page-content-wrapper .content-wrapper.career{
    max-width: 970px;
    margin: 0 auto;
    border-radius: 12px;
    padding: 60px;
    width: 85%;
}
/* .page-content-wrapper .content-wrapper h2{
    margin-bottom: 16px;
} */
.content-wrapper.career .select-field{
    margin-top: 24px;
}
.btn-wrapper{
    margin-top: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.btn-wrapper .btn{
    margin-right: 20px;
}
.btn-wrapper .btn:last-of-type{
    margin-right: 0px;
}
.btn.noUppercase{
    text-transform: unset !important;
}
.errorNote {
    margin: 8px 0 !important;
}
.units-wrapper{
    padding-left: 30px;
    position: absolute;
    top: 160px;
    right: 0;
    width: 320px;
}
.units-wrapper h4{
    text-align: left;
    margin-bottom: 20px;
}
.units-wrapper p, .units-wrapper span{
    text-align: left;
}
.skillchart-wrapper{
    margin-top: 0px;
    position: relative;
    min-height: 700px;
    max-height: 700px;
}
.skillchart-wrapper .units-wrapper{
    top: 0px !important;
    height: 100%;
    padding: 0 30px;
}
.skillchart-wrapper .units-wrapper h3{
    text-align: left;
}
.units-wrapper .job-name{
    margin-bottom: 15px;
    display: flex;
    align-items: center;
}
.units-wrapper .job-name .dot{
    width: 18px;
    border-radius: 50%;
    height: 18px;
    margin-right: 30px;
}
.content-wrapper .table-head-row .compare-skill .skill-level{
    font-size: 14px;
    text-align: left;
}
/* .content-wrapper .table-head-row .compare-skill p{
    margin-bottom: 0px;

} */
.content-wrapper .table-head-row .compare-skill{
    height: 250px;
    padding-left: 40px;
    display: flex;
    align-items: center;
    background: #f3f3f3;
    margin-bottom: 4px;
    /* margin-top: 3px; */
}
.content-wrapper .table-head-row .compare-skill .line{
    /* width: 30px; */
    margin-right: 30px;
    border: 1px solid #8a1e1e;
}

.skill-levels-wrapper h4{
    text-align: left;
    margin-bottom: 10px;
    margin-top: 12px;
}
.skill-levels-wrapper span{
    text-align: left;
}
.chart-filter-wrapper{
    margin-top: 20px;
    
}
.skill-levels-wrapper .skill-levels, .chart-filter{
    margin-top: 16px;
}
.skill-levels-wrapper .skill-levels p, .chart-filter p{
    /* margin-bottom: 5px; */
    display: flex;
    align-items: center;
    margin-top: 3px !important;
}
.bubble-chart img{
    position: absolute;
    bottom: 6%;
}
.bubble-chart .close-speech{
    color: white;
    font-size: 1.1rem!important;
    text-transform: none;
    font-weight: 300;
    position: absolute;
    top: 47%;
    left: 25%;
    z-index: 99999999;
}
.skill-levels-wrapper .skill-levels p .dot, .chart-filter p .dot{
    width: 18px;
    height: 18px;
    border-radius: 50%;
    margin-right: 16px;
    border: 3px solid #fff;
}
.color-dot{
    width: 12px;
    height: 12px;
    border-radius: 50%;
}
.chart-filter{
    border-top: 2px solid #1f1f1f;
    margin-top: 20px;
    padding-top: 20px;
}
.chart-filter p .dot{
    border: none;
}
.skilldarChart{
    width: 100%;
}
.chart-popup .skilldarChart{
    min-height: 620px;
}
.apexcharts-toolbar{
    display: none !important;
}
.skill-levels-wrapper .icon-btn{
    margin-top: 40px;
}
.careerChart-wrapper{
    border-top: 2px solid #1f1f1f;
    margin-top: 30px;
    padding-top: 20px;
    position: relative;
}
.polarChart .apexcharts-legend{
    display: flex;
    align-items: flex-start;
}
.careerChart-wrapper .chart-btn-wrapper{
    margin-top: 0px;
    justify-content: left;
    flex-wrap: wrap;
}
.careerChart-wrapper .chart-btn-wrapper .select-field{
    width: 24%;
    margin: unset;
    margin-left: auto;
}
.careerChart-wrapper .chart-btn-wrapper .select-field .MuiSelect-select{
    padding: 12px !important;
    padding-right: 32px !important;
}
.careerChart-wrapper .chart-btn-wrapper .chart-btngrp{
    display: flex;
    align-items: center;
}
.careerChart-wrapper .chart-btn-wrapper .chart-btngrp button{
    margin: 0;
    color: #637480;
}
.careerChart-wrapper .chart-btn-wrapper .MuiBadge-anchorOriginTopRight{
    right: 12px !important;
}
.careerChart-wrapper .chart-btn-wrapper .chart-btngrp button svg{
    font-size: 30px;
}
.toggle-btn-wrapper{
    width: 46%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.toggle-btn-wrapper button{
    border: 2px solid #000c39 !important;
    border-color: #000c39 !important;
    border-radius: 0 !important;
    color: #000c39;
}
.toggle-btn-wrapper button.Mui-selected{
    background: #000c39 !important;
    color: #fff;
}
.careerChart-wrapper .units-wrapper{
    top: 90px;
}
.careerChart-wrapper .apexcharts-legend .apexcharts-legend-text{
    font-size: 16px !important;
}
.careerChart-wrapper .apexcharts-legend .apexcharts-legend-series{
    margin: 12px 0 !important;
}
.skilldarChart .apexcharts-legend.apexcharts-align-center.apx-legend-position-right{
        width: auto !important;
        position: relative !important;
        /* left: auto; */
        /* top: 0px !important; */
        /* right: -15px; */
        /* bottom: 0px; */
        flex-direction: row !important;
}
.skilldarChart .apexcharts-legend.apexcharts-align-center.apx-legend-position-bottom{
      padding-bottom: 20px;
}
/* .content-wrapper .skillchart-wrapper  */
.list-view-wrapper{
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin-top: 20px;
    max-height: 570px;
    padding-top: 5px;
    overflow-y: auto;
}
.list-view-wrapper::-webkit-scrollbar{
    width: 5px;
    height: 5px;
    border-radius: 3px;
}
.col.transfer-window::-webkit-scrollbar{
    width: 5px;
    height: 5px;
    border-radius: 3px;
}
.list-view-wrapper::-webkit-scrollbar-thumb{
    background-color: #ccc;
    border-radius: 3px;
}
.list-view-wrapper .list-card{
    background: #fff;
    box-shadow: 0px 0px 6px rgba(0,0,0,0.18);
    padding: 10px;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-radius: 6px;
}
.list-view-wrapper .list-card .card-head h4{
    text-align: left;
    margin-bottom: 10px;
}
.list-view-wrapper .list-card .card-head .sector {
    text-align: left;
    display: flex;
    align-items: center;
}
.list-view-wrapper .list-card .card-head .sector .dot{
    width: 18px;
    height: 18px;
    margin-right: 20px;
    border-radius: 50%;
}
.list-view-wrapper .list-card .card-details{
    display: flex;
    margin-top: 20px;
    align-items: center;
    justify-content: space-between;
}
.list-view-wrapper .list-card .card-details > div{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.list-view-wrapper .list-card .card-details > div Button .icon-text{
    min-width: 190px;
}
.list-view-wrapper .list-card .card-details > div Button .icon-text p{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
}.list-view-wrapper .list-card .card-details .icon-text{
    margin-right: 16px !important;
}
.list-view-wrapper .list-card .card-details button{
    text-transform: unset !important;
    max-width: 190px;
}
.list-view-wrapper .list-card .card-details button .icon-text{
    margin-right: 0 !important;
}
.list-view-wrapper .list-card .card-details .btn{
    background: #7040f1 !important;
    padding: 10px 12px !important;
}
.list-view-wrapper .list-card .card-details .btn span{
    font-size: 14px;
    font-weight: 600;
    text-transform: none !important;
}
.export-report{
    display: none;
}
.btn-wrapper.career-history{
    flex-wrap: wrap;
    margin-top: 60px;
}
.btn-wrapper.career-history p{
    margin: 0 12px;
    font-size: 60px;
    color: #637480;
}
.btn-wrapper.career-history h3{
    width: 100%;
}
.content-wrapper.career{
    text-align: center;
}
.content-wrapper.career .select-field{
    text-align: left;
    margin: 0 !important;
}
.app-popup.career-goal .MuiPopover-paper{
    width: 50%;
    left: 25% !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
    padding: 30px 40px;
}
.app-popup.career-goal .popup-head{
    display: block;
    margin-bottom: 30px;
}
.app-popup.career-goal .popup-head h2{
    margin-bottom: 12px;
}
.app-popup.career-goal .select-field{
    margin-top: 24px;
}
.app-popup.career-goal .btn-wrapper button:last-of-type{
    margin-left: 20px;
}
.content-wrapper.career .fields{
    margin-top: 40px;
}
.content-wrapper.career .field-wrapper{
    max-width: 360px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    margin-top: 20px;
    flex-wrap: wrap;
    position: relative;
}
.content-wrapper.career .field-wrapper button{
    position: absolute;
    right: -85px;
}
.content-wrapper.career .addField-btn-wrapper{
    max-width: 360px;
    justify-content: center;
    display: flex;
    margin: 0 auto;
    margin-top: 20px;
}
.content-wrapper.career .step-content{
    max-width: 300px;
    margin: 0 auto;
    margin-top: 40px;
}
.app-modal.careerDetails, .app-modal.compare-career{
    opacity: 0;
    right: 0;
    top: 0;
    height: 100vh;
    width: 0;
    display: flex;
    transition: 0.4s ease-in-out;
    justify-content: flex-end;
    z-index: 10000;
}
.app-modal.careerDetails .popup-wrapper{
    overflow-x: hidden;
}
.app-modal.careerDetails .popup-wrapper, .app-modal.compare-career .popup-wrapper{
    width: 0;
    transition: 0.4s ease-in-out;
    height: 100vh;
    background: #fff;
    max-height: 100vh;
    overflow-y: auto;
    top: 0 !important;
    transition: width 0.6s ease-in-out;
    border-radius: 0px ;
    right: 0px;
    position: absolute;
}
.app-modal.careerDetails.open, .app-modal.compare-career.open{
    width: 100%;
    opacity: 1;
}
.app-modal.careerDetails.open .popup-wrapper, .app-modal.compare-career.open .popup-wrapper{
    width: 90%;
    visibility: visible;
}
.app-modal.careerDetails.open .popup-wrapper::-webkit-scrollbar, .app-modal.compare-career.open .popup-wrapper::-webkit-scrollbar{
    width: 8px;
}
.app-modal.careerDetails.open .popup-wrapper::-webkit-scrollbar-thumb, .app-modal.compare-career.open .popup-wrapper::-webkit-scrollbar-thumb{
    background: #000c39;
    border-radius: 4px;
}
.app-modal.open.careerDetails .popup-wrapper{
    padding: 40px 32px;
    padding-bottom: 20px;
}
.app-modal.compare-career.open .popup-wrapper{
    padding-top: 40px;
    padding-bottom: 5px;
}
.app-modal.compare-career .popup-wrapper .popup-head span{
    text-align: left !important;
}
.app-modal.compare-career .popup-wrapper .popup-head .comparison-btn{
    margin-right: 50px !important;
}
.app-modal.compare-career .content-wrapper{
    display: flex;
    margin-top: 40px;
}
.app-modal.careerDetails  .content-wrapper{
    overflow: hidden;
}
.app-modal.compare-career .content-wrapper .table-head-row, .app-modal.compare-career .content-wrapper .career-row {
    width: 16%;
    border-right: 1px solid #fff;
}
.app-modal.compare-career .content-wrapper .table-head-row{
    width: 20%;
    min-width: 320px;
}
@media only screen and (max-width: 1850px){
    .app-modal.compare-career .content-wrapper .table-head-row{
        min-width: 300px;
    }
    .app-modal.compare-career .content-wrapper .career-row{
        width: 23%;
    }
    .app-modal.compare-career .content-wrapper .table-head-row .compare-data{
        padding-left: 28px !important;
    }
    .content-wrapper .table-head-row .compare-skill{
        padding-left: 28px;
    }
}
@media only screen and (max-width: 1400px){
    .comapre-skilldar.popup {
        min-width: 60vw;
        min-height: 60vh;
    }
}
@media only screen and (max-width: 1400px){
    .app-popup.compare-career .content-wrapper .table-head-row, .app-popup.compare-career .content-wrapper .career-row {
        max-width: 25vw;
        min-width: 25vw;
    }
}
@media only screen and (max-width: 1200px){
    .app-popup.compare-career .content-wrapper .table-head-row, .app-popup.compare-career .content-wrapper .career-row {
        max-width: 30vw;
        min-width: 30vw;
    }
}
.app-modal.compare-career .content-wrapper .table-head-row p{
    margin-bottom: 12px;
}

.app-modal.compare-career .content-wrapper .table-head-row span{
    margin-bottom: 4px;
}
.app-modal.compare-career .content-wrapper .table-head-row .compare-data{
    /* background-color: #f3f3f3; */
    min-height: 35px;
    line-height: 4;
    text-align: left;
    padding-left: 40px;
    margin-right: 1px;
}
.app-modal.compare-career .content-wrapper .career-row{
    /* border-bottom: 1px solid #ccc; */
    min-width: 200px;
    border-left: 2px solid white;
    border-right: 2px solid white;
}
.app-modal.compare-career .content-wrapper .career-row .head {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 8px 8px;
    padding-bottom: 20px;
}
.speech {
    background-color:#fff;
    border:solid 2px #000;
    border-radius:12px;
    display:inline-block;
    margin:.5em;
    padding:.5em 1em;
    position:absolute;
    width: 260px;
    z-index: 999;
    bottom: 126px;
    left: 160px;
}
.speech:before {
    border: solid 12px transparent;
    border-left:solid 12px #000;
    border-top:solid 12px #000;
    bottom:-24px;
    content:"";
    height:0;
    left:24px;
    position:absolute;
    transform:skew(-15deg);
    width:0;
  }
  
  .speech:after {
    border:solid 10px transparent;
    border-left:solid 10px #fff;
    border-top:solid 10px #fff;
    bottom:-19px;
    content:"";
    height:0;
    left:27px;
    position:absolute;
    transform:skew(-15deg);
    width:0;
  }
.app-modal.compare-career .content-wrapper .career-row .head .role-label{
    position: absolute;
    /* bottom: -10px; */
    transform: translateX(-50%);
    left: 50%;
    /* background: #fff; */
    padding: 0 5px;
}
.app-modal.compare-career .content-wrapper .career-row .content{
    /* padding: 12px; */
    text-align: center;
}
.app-modal.compare-career .content-wrapper .career-row .chart-wrapper{
    min-height: 250px;
    width: 100%;
    overflow: hidden;
    margin: 4px 0;
}
.app-modal.compare-career .popup-content-wrapper::-webkit-scrollbar{
    height: 10px;
    border-radius: 5px;
    width: 6px;
}
.app-modal.compare-career .popup-content-wrapper::-webkit-scrollbar-thumb{
    background: #000c39;
    border-radius: 5px;
}
.app-modal.compare-career .content-wrapper .career-row .item-wrapper span{
    margin-bottom: 4px;
}
.app-modal.compare-career .content-wrapper .career-row .item-wrapper .compare-data{
    background-color:#f3f3f3;
    min-height: 35px;
    line-height: 4;
}
.app-modal.compare-career .content-wrapper .career-row .content button{
    margin-top: 14px;
}
.app-modal.compare-career .content-wrapper .career-row .content button.icon-btn span svg{
    font-size: 24px;
    color: #637480 !important;
}
.app-modal.compare-career .content-wrapper .career-row .content button.icon-btn p{
    font-size: 14px;
}
.app-modal.compare-career .content-wrapper .career-row .content button.icon-btn span:first-of-type{
    margin-right: 0px;
}
.app-popup.careerDetails.dekstop{
    display: block;
}
.app-popup.careerDetails.mobile{
    display: none;
}
.app-popup.careerDetails .popup-head .icon-text .icon svg{
    font-size: 24px;
}
.app-popup.careerDetails .content-wrapper{
    min-height: calc(100% - 84px);
    justify-content: space-between;
}
.flex-around{
    display: flex;
    align-items: center;
    /* padding-right:30px; */
}
.popup-head .close-career-popup{
    color:white;
    height:40px;
    width:40px;
    font-size: 35px;
    font-weight:800 !important;
}
.app-popup.careerDetails .row{
    width: 67%;
}
.rowMax{
    width: 32%;
}
.content-wrapper{
    margin-top: 0px;
}
.content-wrapper .rowMax .css-13fhbnr-MuiButtonBase-root-MuiTab-root:not(.Mui-selected) {
    color: #0B0B45 ;
    font-weight: 700;
}
.content-wrapper .rowMax .MuiTabs-indicator.css-1t7h7nb-MuiTabs-indicator{
    background-color:currentColor;
    bottom: 6px;
}
.app-popup.skilldar.getskill-report .css-qimweh-MuiButtonBase-root-MuiCheckbox-root.Mui-checked{
    color: currentColor !important;
}
.app-popup.skilldar.getskill-report .css-1l9j6zp-MuiButtonBase-root-MuiButton-root:hover{
    box-shadow: none;
}
.content-wrapper .rowMax  .popup-tab .MuiTabs-flexContainer{
   justify-content: space-evenly;
   flex-wrap: wrap;
}
.content-wrapper .rowMax  .popup-tab .MuiBox-root  {
    padding: 5px 0 !important;
}
.content-wrapper .rowMax  .popup-tab  .css-1gsv261 {
    border-bottom: none !important;
}
.content-wrapper .rowMax .tabs-size{
    font-size: 1rem !important;
    text-transform: none;
    font-weight: 700;
}
.app-popup.careerDetails .row .col, .app-popup.careerDetails .rowMax .col{
    padding: 20px;
    border: 1px solid #8f8b8b;
    border-radius: 10px;
    margin-bottom: 18px;
    background-color: #f4f4f4;
}
.app-popup.careerDetails .row .desc-head{
    border: 1px solid #8f8b8b;
    border-radius: 10px;
    margin-bottom: 18px;
    background-color: #f4f4f4;
    
}
.row .desc-head .description{
    border-radius: 9px 9px 0px 0px;
    text-align:start;
    padding: 8px 15px;
}
.app-popup{
    z-index: 10000 !important;
}
/* max-width: 480px; */
.app-popup.careerDetails .popup-head > div:first-child{
    display: flex;
    justify-content: left;
}
.app-popup.careerDetails .popup-head .MuiBox-root:last-of-type{
    /* style={{display:"flex", justifyContent: 'right', flexWrap: 'wrap'}} */
    display: flex;
    justify-content: right;
    /* flex-wrap: wrap; */
    
}
.app-popup.careerDetails .popup-head h3{
    text-align: left;
}
.app-popup.careerDetails .rowMax .col.tab-wrapper{
    padding: 20px 5px;
}
.app-popup.careerDetails .row .col h5, .app-popup.careerDetails .rowMax .col h5{
    text-align: center;
    margin-bottom: 12px;
}
.average-box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 40px 10px !important;
}
.average-box .heading{
    text-align: center;
}
.app-popup.careerDetails .rowMax .col{
    background-color: #f4f4f4;
    /* padding: 15px; */
    border-radius: 10px;
    margin-bottom: 18px;
}
.app-popup.careerDetails .rowMax  .transfer-window{
    padding: 15px 25px !important;
}
.app-popup.careerDetails .rowMax .skilldar{
    max-height: 450px;
}
.app-popup.careerDetails .row .col.courses-wrapper{
    max-height: 500px;
    padding: 0px;
}
.app-popup.skilldar.getskill-report .css-m6xx7k-MuiButtonBase-root-MuiCheckbox-root.Mui-checked{
    color: #3e16aa !important;
}
.career-responsive-btns.MuiBox-root{
    display: flex !important;
}
.app-popup.careerDetails .row .col.courses-wrapper .heading{
    text-align:start;
    padding: 8px 15px;
    /* border-radius: 12px 12px 0px 0px; */
}
.col.courses-wrapper{
    overflow-y: auto;
}
.app-popup.careerDetails .career-popup-head-btn{
    color: white;
    border:2px solid #0B0B45;
    border-radius: 3px;
    padding: 5px 15px;
    margin-left: 20px;
    margin-bottom: 4px;
}
.app-popup.compare-career .career-popup-head-btn{
    color: white;
    border:2px solid #0B0B45;
    border-radius: 3px;
    padding: 5px 15px;
    margin-left: 20px;
}
.app-popup.careerDetails .row .col.courses-wrapper::-webkit-scrollbar{
    width: 5px;
}
.app-popup.careerDetails .row .col.courses-wrapper::-webkit-scrollbar-thumb{
    background: #ccc;
}
.getskill-report .MuiPopover-paper {
    width: 50% !important;
}
.app-popup.careerDetails .row .col ul{
    max-height: 360px;
    overflow-y: auto;
    padding-left: 20px;
}
.app-popup.careerDetails .row .col ul::-webkit-scrollbar{
    width: 3px;
}
.app-popup.careerDetails .row .col ul::-webkit-scrollbar-thumb{
    background: #ccc;
    border-radius: 2px;
}
.app-popup.careerDetails .row .col ul li span{
    text-align: left !important;
}
.app-popup.careerDetails .row .heading{
    margin-bottom: 8px;
}
.app-popup.careerDetails .row .MuiAccordion-rounded{
    margin-bottom: 12px;
    border-bottom: 3px solid white;
}
.app-popup.careerDetails .row .MuiAccordion-rounded::before{
    background-color: unset !important;
}
.app-popup.careerDetails .row .MuiAccordion-rounded .MuiAccordionSummary-root{
    min-height: unset !important;
}
.app-popup.careerDetails .row .MuiAccordion-rounded .MuiAccordionSummary-content{
    margin: 0;
}
.app-popup.careerDetails .row .MuiAccordion-rounded span{
    text-align: left;
}
.app-popup.careerDetails .row .MuiAccordion-rounded .MuiAccordionSummary-expandIconWrapper.Mui-expanded{
    transform: rotate(90deg); 
}
.app-popup.careerDetails .row .MuiAccordion-rounded:last-of-type{
    border-bottom: none !important;
}
.app-popup.chart {
    background: unset !important;
}
.app-popup.chart .MuiPaper-rounded{
    padding: 16px 12px;
    min-width: 300px;
}
.app-popup.chart .popup-head{
    display: block !important;
}
.app-popup.chart .popup-head span, .app-popup.chart .popup-head h4{
    text-align: left !important;
}
.app-popup.chart .content-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 14px 0;
}
.app-popup.chart .content-wrapper .icon-text{
    font-size: 12;
    margin-right: 10px;
}
.app-popup.chart .content-wrapper .icon-text span{
    width: 24px;
    height: 24px;
}
.app-popup.chart .content-wrapper .icon-text span svg{
    font-size: 16px;    
}
.app-popup.chart .btn-wrapper{
    margin-top: 10px !important;
    justify-content: space-between;
}
.app-popup.chart .btn-wrapper button{
    text-transform: unset !important;
    margin-right: 0;
}
.app-popup.chart .btn-wrapper button .icon{
    border-radius: 50%;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}
.app-popup.chart .btn-wrapper button .icon svg{
    font-size: 22px;
}
.apexcharts-legend-series{
    margin: 10px 10px !important;
    /* width: 100%; */
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
}
.apexcharts-legend .apexcharts-legend-text{
    font-size: 13px !important;
    text-align: left !important;
    font-family: Public Sans,sans-serif !important;
    padding-left: 24px;
    line-height: 20px;
    /* width: calc(100% - 14px); */
    font-weight: 600 !important;
    color: #637480 !important;
}
.apexcharts-legend-marker{
    width: 14px !important;
    height: 14px !important;
}
.code-content h3 {
    margin-bottom: 12px;
}
.code-content .field-wrapper {
    max-width: 440px;
    margin: 30px auto;
    display: flex;
    justify-content: space-between;
}
.code-content .field-wrapper button{
    margin-left: 12px;
}
.code-content .box-content{
    margin-top: 80px !important;
    max-width: 820px;
    margin: 0 auto;
    border-radius: 20px;
    padding: 30px 40px;
    background: #6f40f11a;
    text-align: center;
}
.code-content .box-content .btn{
    margin-top: 30px;
}

.popup-tab h5,.popup-tab h1, .popup-tab h3, .popup-tab h4{
    text-align: center;
}
.popup-tab .MuiTab-root, .popup-tab .MuiTabs-root{
    font-size: 12px !important;
    min-height: 36px !important;
}
.tab-content .stats-wrapper{
    margin-top: 20px;
}
.tab-content .stats-wrapper h1{
    margin-bottom: 10px;
}
.popup-tab .MuiBox-root{
    padding: 20px 0 !important;
}
.popup-tab .tab-content{
    padding: 0 !important;
}
/* .popup-tab .tab-content .apexcharts-xaxis-texts-g{
    display: none;
} */
.popup-tab .tab-content::-webkit-scrollbar{
    width: 5px;
    border-radius: 3px;
}
.popup-tab .tab-content::-webkit-scrollbar-thumb{
    background-color: #dfdfdf;
    border-radius: 3px;
}
.popup-tab .stats-wrapper{
    padding: 0 !important;
}
.stats-wrapper .stats{
    padding: 10px 0 !important;
}
.popup-tab .MuiTabs-flexContainer{
    justify-content: space-between;
}
.popup-tab .MuiTabs-flexContainer button{
    margin-right: 5px !important;
}
#chart-popup .MuiPopover-paper {
    max-width: 420px !important;
}
.alert-wrapper{
    position: fixed;
    max-width: 360px;
    min-width: 280px;
    z-index: 100000;
    top: 30px;
    right: 30px;
}
.alert-wrapper .MuiAlert-icon {
    align-items: center;
}
.app-popup.careerDetails .row .col .radarWrapper{
    cursor: pointer;
}
.units-wrapper .currentRole-text{
    margin-top: 20px;
}
.career-chart .apexcharts-legend .apexcharts-legend-series:first-child{
    padding-bottom: 20px !important;
    border-bottom: 1px solid #ccc;
    position: relative;
}
.career-chart .apexcharts-legend .apexcharts-legend-series:first-child::before{
    content: "Your current role";
    color: #637480;
    position: absolute;
    bottom: 6px;
    right: 10px;
    font-size: 12px;
}
.inner-step-wrapper{
    display: flex;
    margin-top: 100px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* height: calc(100vh - 460px); */
}
.inner-step-wrapper .step-btn{
    padding: 0;
}
.inner-step-wrapper .step-btn svg{
    font-size: 80px;
}
.inner-step-wrapper .step-btn.prev svg path{
    transform: translateX(6px);
}
.select-field.black .css-1jqq78o-placeholder, .select-field.black .css-1dimb5e-singleValue {
    color: #000c39 !important;
    font-size: 14px !important;
}
.linkBtn{
    padding: 0 !important;
}
.linkBtn a{
    padding: 12px 48px;
}

.select-field.black .css-13cymwt-control, .select-field.black .css-t3ipsp-control{
    border-color: #ccc !important;
}
.page-content-wrapper .inner-step-wrapper .content-wrapper h2{
    margin-bottom: 5px;
}
.page-content-wrapper .inner-step-wrapper .content-wrapper .head{
    max-width: 620px;
    margin: 0 auto;
}
.inner-step-wrapper .step-content-wrapper{
    margin-top: 10px;
}
.inner-step-wrapper .step-content-wrapper .select-field{
    margin: 0 auto !important;
    margin-top: 30px !important;
}
.bar-chart-wrapper{
    border-radius: 10px;
    max-height: 700px;
    min-height: 700px;
    flex-wrap: nowrap;
}
.bar-chart-wrapper .filter-wrapper{
    color: white;
    width: 35%;
    overflow-y: auto;
    padding: 16px 30px;
}
.bar-chart-wrapper .filter-wrapper::-webkit-scrollbar{
    width: 8px;
    border-radius: 4px;
    height: 8px;
}

.bar-chart-wrapper .filter-wrapper::-webkit-scrollbar-thumb{
    background-color: #f4f4f4;
    border-radius: 4px;
}
.bar-chart-wrapper .filter-wrapper h4{
    text-align: left;
}
.bar-chart-wrapper .bar-chart{
    width: 65%;
}
.select-field.small{
    width: 220%;
    margin-right: 10px;
}
.select-field.small .css-13cymwt-control, .select-field.small .css-t3ipsp-control{
    padding: 2px;
}
.bar-chart-wrapper .list-view-wrapper{
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin-top: 0;
    padding-left: 0;
}
.bar-chart-wrapper .list-view-wrapper , .bar-chart-wrapper .bar-wrapper{
    height: 540px;
    margin: 10px;
    margin-left: 30px;
    margin-top: 0;
    padding-right: 10px;
    margin-bottom: 10px;
    overflow-y: scroll;
}
.bar-chart-wrapper .list-view-wrapper::-webkit-scrollbar, .bar-chart-wrapper .bar-wrapper::-webkit-scrollbar {
    width: 8px;
    border-radius: 4px;
    height: 8px;
}
.bar-chart-wrapper .list-view-wrapper::-webkit-scrollbar-track, .bar-chart-wrapper .bar-wrapper::-webkit-scrollbar-track {
    background-color: #f0f0f0;
    border-radius: 4px;
}
.bar-chart-wrapper .list-view-wrapper::-webkit-scrollbar-thumb, .bar-chart-wrapper .bar-wrapper::-webkit-scrollbar-thumb {
    background-color: #000c39;
    border-radius: 4px;
}
.bar-chart-wrapper .list-view-wrapper::-webkit-scrollbar-thumb:hover, .bar-chart-wrapper .bar-wrapper::-webkit-scrollbar-thumb:hover {
    background-color: #1e0a4f;
}
.list-view-wrapper .list-item {
    display: flex;
    align-content: center;
    justify-content: space-between;
    gap: 16px;
    background-color: #dfdfdf;
    color: white;
    border-radius: 0;
    min-width: 760px;
}
.list-view-wrapper .list-item .list-content{
    display: flex;
    align-items: center;
    justify-content: left;
}
.bar-wrapper .bar{
    padding: 12px 0;
    margin: 2px 0;
    border-radius: 12px;
    color: white;
    cursor: pointer;
}
.bar-wrapper .sub-bar{
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    margin-right: 20px; 
    border-radius: 12px
}
.bar-wrapper .sub-bar h6{
    margin-bottom: 6px;
}
.bar-chart-wrapper .bar-chart .bar-head{
    padding: 16px 30px;
    display: flex;
    justify-content: space-between;
}
.chart-popup{
    z-index: 10000 !important;
}
.chart-popup .MuiPaper-root{
    width: 55%;
    right: 20%;
    left: unset !important;
    top: 20px !important;
    padding: 30px 30px;
}
.chart-popup .MuiPaper-root .close-btn{
    position: absolute;
    top: 20px;
    right: 20px;
}
.chart-popup .MuiPaper-root .close-btn svg{
    font-size: 40px;
}
.page-content-wrapper .content .MuiStepper-root .MuiStepConnector-line{
    border-top-width: 5px;
    border-color: #f4f4f4 !important;
}
.page-content-wrapper .content .MuiStepper-root .MuiStepConnector-root{
    top: 30px !important;
    left: calc(-50% + 35px) !important;
    right: calc(50% + 35px) !important;
}
.page-content-wrapper .content .MuiStepper-root .step-icon{
    width: 52px;
    height: 52px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.page-content-wrapper .content .MuiStepper-root .step-icon .svg-color{
    width: 40px;
    height: 40px;
}
.chart-popup .skilldarChart .apexcharts-canvas{
    margin: 0 auto;
}
.ai-video-wrapper{
    padding-top: 20px;
    align-self: center;
} 
.ai-video-wrapper .laicie-background {
    width: 100%;
} 
.video-position{
    display: flex;
    justify-content: flex-start;
}
.laicie-background img{
    object-fit: cover;
    width: 400px;
    height: 400px !important;
    margin: auto;
    transform: translateY(4%);
}

.MuiDialog-container .MuiPaper-root.MuiPaper-elevation .css-iqk9mm{
    width: 40% !important;
    height: 50% !important;
}
.MuiDialog-container .MuiPaper-root.MuiPaper-elevation{
    box-shadow: unset !important ;
}
.chart-popup .popup-chart-wrapper{
    flex-wrap: nowrap;
}

@media only screen and (max-width: 1700px){
    .app-popup.skilldar.getskill-report .css-1fjg0ng-MuiButtonBase-root-MuiButton-root {
        padding-left: 15px;
        padding-right: 15px;
        font-size: 1rem;
    }
    .skilldar-keys{
        height: 25% !important;
        width: 18% !important;
    }
}

@media only screen and (max-width: 1440px){
    .app-modal.compare-career .popup-wrapper .popup-head span{
        font-size: 0.8rem !important;
    }
    .chart-popup .popup-chart-wrapper{
        flex-direction: column;
    }
    .chart-popup .popup-chart-wrapper .MuiGrid-root{
        max-width: 100% !important;
        flex-basis: unset !important;
    }
    .chart-popup .popup-chart-wrapper .MuiGrid-root:first-of-type{
        height: 200px;
    }
    .chart-popup .popup-chart-wrapper .skilldar-keys{
        width: 100% !important;
        position: unset;
        height: 100% !important;
        margin: 0;
        margin-top: 20px;
        display: flex;
        justify-content: center;
    }
    .chart-popup .popup-chart-wrapper .skilldar-keys > div {
        width: 20%;   
    }
    .skilldar-keys{
        height: 25% !important;
        width: 18% !important;
    }
    .popup-keys{
        flex-direction: column;
    }
    .app-popup.skilldar .slider-content-wrapper {
        min-height: 400px;
    }
    .bubble-chart img{
        width: 96%;
        right: 6%;
    }
    .bubble-chart .close-speech{
        left: 22%;
        top:48%;
    }
    .app-popup .slider-content-wrapper .content-col.right-col .bottom-spacing{
        margin-bottom: 30px;
    }
    .app-popup.careerDetails .rowMax .col.tab-wrapper{
        padding: 20px 8px;
    }
    /* .laicie-background {
        width: 140px !important;
        height: 140px !important;
    } */
    /* .laicie-background {
        width: 280px !important;
        height: 160px !important;
    } */
    /* .laicie-background .MuiBox-root{
        width: 40px;
        height: 40px;
        bottom: 5%;
        left: 50%;
        transform: translateX(-50%);
    } */
}
@media (min-width: 1200px){
    .css-1fjg0ng-MuiButtonBase-root-MuiButton-root {
        padding-left: 15px;
    }  
}
.css-14plz42 video{
    object-fit: contain !important;
}
@media only screen and (max-width: 1279px){
    .MuiDialog-container .MuiPaper-root.MuiPaper-elevation .css-iqk9mm{
        /* width: 100% !important; */
        width: 50% !important;
    } 
    .close-icon{
        /* right:8.3% !important */
        right:25% !important
    }
    .bubble-chart img{
        width: 92%;
        right: 13%
    }
    .bubble-chart .close-speech{
        left: 18%;
        top:51%;
    }
    .logo img{
        max-width: 160px;
    }
    .bradford-logo-wrapper img{
        max-width: 240px;
    }
    .btn{
        padding: 8px 20px !important;
    }
    .nav-wrapper .btn{
        margin-left: 20px;
    }
    .skilldarChart .apexcharts-canvas{
        width: 100% !important; 
        /* height: unset !important; */
    }
    .app-popup.skilldar .MuiPopover-paper{
        width: 80%;
        left: 25% !important;
    }
    .app-popup .slider-content-wrapper .content-col.right-col{
        height: 260px;
        margin-top: 30px;
    }
    .app-popup.skilldar .slider-content-wrapper{
        min-height: 560px !important;
    }
    .careerChart-wrapper .chart-btn-wrapper .chart-btngrp .toggle-btn-wrapper button{
        padding: 5px !important;
        min-width: 50px;
    }
    .chart-btn-wrapper .icon-btn-outlined{
        padding: 6px 8px !important;
        margin-right: 10px !important;
    }
    .careerChart-wrapper .chart-btn-wrapper .select-field .MuiSelect-select{
        padding: 8px !important;
        padding-right: 20px !important;
    }
    .careerChart-wrapper .chart-btn-wrapper .chart-btngrp .toggle-btn-wrapper{
        margin: 0 10px;
    }
    .app-popup.careerDetails .row .col ul{
        padding-left: 20px !important;
        max-height: 330px;
    }
    .bar-chart-wrapper .bar-chart .bar-head{
        padding: 20px;
    }
    .bar-chart-wrapper .list-view-wrapper, .bar-chart-wrapper .bar-wrapper{
        margin-left: 20px;
    }
    .app-popup.careerDetails .content-wrapper{
        flex-direction: column;
    }
    .app-popup.careerDetails .row, .app-popup.careerDetails .rowMax{
        width: 100%;
    }
    .app-popup.careerDetails .rowMax .col-wrapper{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }
    .app-popup.careerDetails .rowMax .col-wrapper .col{
        width: 49%;
    }
    /* .app-popup.careerDetails .row .col.courses-wrapper{
        max-height: 320px;
        min-height: 320px;
    } */
}
@media only screen and (max-width: 1199px){
    .app-popup .slider-content-wrapper .content-col.right-col .bottom-spacing{
        margin-bottom: 60px;
    }
    .app-popup.skilldar .slider-content-wrapper{
        min-height: 480px !important;
    }
    .careerChart-wrapper .chart-btn-wrapper .select-field{
        width: 36%;
        margin-top: 20px;
        margin-right: 0;
    }
    .skillchart-wrapper .units-wrapper{
        padding: 0 20px;
    }
    /* .bar-chart-wrapper .filter-wrapper > div{
        padding: 20px;
    } */
}
@media only screen and (max-width: 1024px){
    .MuiContainer-root{
        padding-left: 24px !important;
        padding-right: 24px !important;
    }
    .page-content-wrapper.home .content .entry-text{
        max-width: 50%;
    }
    .bubble-chart img{
        width: 93%;
        left: 10%;
        top: 0%;
        height: 100%;
        object-fit: cover;
    }
    .bubble-chart .close-speech{
        left: 51%;
        top: 53.6%;
        font-size: 1rem !important;
    }
    .bubble-chart.career-courses{
        transform: translate(3%, 54%) !important;
    }
    .app-popup .slider-content-wrapper .content-col.right-col{
        height: 230px;
        margin-top: 0px;
    }
    .app-popup.skilldar .slider-content-wrapper{
        min-height: 400px !important;
    }
    .list-view-wrapper{
        max-height: 500px;
    }
    .list-view-wrapper .list-card .card-details > div{
        display: grid;
        grid-template-columns: repeat(2,1fr);
        grid-gap: 20px;
    }
    .list-view-wrapper .list-card .card-details button{
        padding: 0;
        justify-content: left;
    }
    .app-popup.careerDetails .MuiPopover-paper{
        width: 90%;
    }
    /* .app-popup.compare-career .content-wrapper{
        flex-wrap: wrap;
    } */
    .app-popup.compare-career .content-wrapper .row:last-child{
        width: 70%;
        margin: 0 auto;
    }
    .inner-step-wrapper{
        margin-top: 60px;
    }
    .inner-step-wrapper .content-wrapper {
        width: 84% !important;
    }
    .inner-step-wrapper .step-btn svg{
        font-size: 50px;
    }
    .skillchart-wrapper .chart-wrapper {
        flex-wrap: wrap;
    }
    .skillchart-wrapper .units-wrapper{
        position: unset;
        width: 100%;
        height: unset !important;
        padding: 20px 30px;
    }
    .skilldarChart .apexcharts-canvas{
        width: fit-content !important;
        margin: 0 auto;
    }
    .bar-chart-wrapper{
        max-height: unset;
        flex-wrap: wrap;
    }
    .bar-chart-wrapper .bar-chart{
        width: 100%;
    }
    .bar-chart-wrapper .filter-wrapper{
        width: 100%;
    }
    .bar-chart-wrapper .filter-wrapper > div{
        flex-direction: column;
        flex-wrap: wrap;
    }
    .bar-chart-wrapper .filter-wrapper .filter{
        width: 50%;
    }
    .bar-chart-wrapper .filter-wrapper .filter span{
        margin-top: 0px;
    }
    .bar-chart-wrapper .filter-wrapper .filter .MuiStack-root .MuiBox-root{
        margin-top: -14px;
    }
    .bar-chart-wrapper .filter-wrapper .selected-career-wrapper > div{
        margin-top: 0;
        margin-bottom: 12px;
    }
    .bar-chart-wrapper .list-view-wrapper, .bar-chart-wrapper .bar-wrapper{
        height: 500px;
        /* max-height: 500px;
        height: auto;
        margin-bottom: 16px; */
    }
    .bar-chart-wrapper .list-view-wrapper, .bar-chart-wrapper .bar-wrapper-mob{
        /* height: 500px; */
        max-height: 500px;
        height: auto;
        margin-bottom: 16px;
    }
    .bar-chart-wrapper .filter-wrapper .filter p{
        font-size: 12px !important;
    }
    /* .ai-video-wrapper{
        align-self: flex-start;
    } */
    .ai-video-wrapper .laicie-background {
        /* width: 50%; */
        width: 100%;
    }
    .app-popup.skilldar .MuiPopover-paper{
        width: 75% !important;
        left: 13% !important;
    }
    .chart-popup .MuiPaper-root{
        width: 90%;
        transform: translateX(50%) !important;
        right: 50%;
    }
    .app-modal.compare-career .content-wrapper .table-head-row .compare-data, .app-modal.compare-career .content-wrapper .career-row .item-wrapper .compare-data{
        line-height: 3;
    }
    .skillchart-wrapper{
        max-height: unset !important;
    }
}
@media only screen and (max-width: 899px){
    .page-content-wrapper.home .content .entry-text{
        max-width: 80%;
    }
    .page-content-wrapper .content-wrapper.career{
        position: relative;
    }
    .page-content-wrapper .inner-step-wrapper .content-wrapper h2{
        margin-left: 0 !important;
    }
    .page-content-wrapper .content-wrapper.career .head svg{
        position: absolute;
        top: 10px;
        left: 14px;
    }
    .app-popup.careerDetails .popup-head > div:first-child {
        width: 100% !important;
    }
    .app-popup.careerDetails .popup-head .MuiBox-root:last-of-type{
        /* justify-content:center; */
        flex-wrap: wrap;
    }
    .bubble-chart{
        transform: translate(-4%,25%) !important;
    }
    .bubble-chart img{
        width: 86%;
        left: 22%;
        /* top: 11% */
    }
    .bubble-chart .close-speech{
        left: 61%;
        font-size: 0.9rem !important;
    }
    .bubble-chart.career-courses{
        transform: translate(0%, 54%) !important;
        width: 96%;
    }
    .bubble-chart.career-courses img{
        width: 80%;
        left: 16%;
    }
    .bubble-chart.career-courses .close-speech{
        left: 52%;
    }
    .content-wrapper .table-head-row .compare-skill{
        height: 200px;
    }
    .app-modal.compare-career .content-wrapper .career-row .chart-wrapper{
        min-height: 200px !important;
    }
    .logo img{
        max-width: 120px;
    }
    .logo{
        margin-right: 20px !important;
    }
    .bradford-logo-wrapper{
        padding-left: 20px;
    }
    .bradford-logo-wrapper img{
        max-width: 180px;
        margin-top: 10px;
    }
    .page-content-wrapper{
        padding-top: 70px;
    }
    .AppFooter{
        padding: 20px 16px;
        padding-bottom: 0;
    }
    .select-field .MuiSelect-select{
        padding: 8px !important;
        padding-right: 32px !important;
    }
    .select-field .MuiSelect-select em{
        font-size: 14px !important;
    }
    .MuiMenuItem-root{
        min-height: unset !important;
        font-size: 12px !important;
    }
    .skillchart-wrapper{
        margin-top: 0;
        justify-content: center;
    }
    .units-wrapper{
        margin-top: 20px !important;
        padding: 0;
        /* max-width: 540px !important; */
    }
    .units-wrapper .job-name{
        display: none;
    }
    .units-wrapper .icon-btn{
        width: 100%;
    }
    .app-popup.skilldar .slider-content-wrapper{
        min-height: 360px !important;
    }
    .app-popup .slider-content-wrapper .content-col.right-col .bottom-spacing{
        margin-bottom: 30px;
    }
    .app-popup.skilldar .MuiPopover-paper {
        padding: 30px 20px;
    }
    .app-popup .popup-head .close-btn{
        margin-left: 0 !important;
    }
    .app-modal.compare-career .content-wrapper .career-row .head{
        height: 110px;
    }
    .app-popup.compare-career .content-wrapper .table-head-row > span:first-of-type{
        height: 110px;
    }
    .careerChart-wrapper{
        max-width: 512px;
        justify-content: center;
        margin: 0 auto;
        margin-top: 20px;
    }
    .career-chart .apexcharts-canvas{
        margin: 0 auto;
    }
    .careerChart-wrapper .chart-btn-wrapper{
        justify-content: center;
    }
    .apexcharts-text{
        font-size: 12px;
    }
    .chart-filter p{
        margin-bottom: 10px;
    }
    .icon-text span{
        width: 30px;
        height: 30px;
    }
    .icon-text span svg{
        font-size: 18px;
    }
    .app-modal.compare-career .content-wrapper .career-row{
        min-width: 180px;
    }
    .chart-filter-wrapper .chart-filter{
        display: grid;
        grid-template-columns: repeat(2,1fr);
        grid-column-gap: 10px;
    }
    .chart-filter-wrapper .chart-filter p{
        place-self: center;
        width: 162px;
    }
    .chart-filter p .dot{
        margin-right: 10px;
    }
    .page-content-wrapper.home .link-wrapper button{
        font-size: 48px;
    }
    .sidebar-nav .sidebar-wrapper .clg-logo{
        max-height: 100px;
    }
    .chart-popup .skilldarChart{
        min-height: 560px !important;
    }
    .AppFooter .logo img{
        max-width: 100px;
        margin-left: 12px;
    }
    .inner-step-wrapper{
        margin-top: 30px;
    }
    .select-field .css-1jqq78o-placeholder, .select-field .css-1dimb5e-singleValue, .select-field .css-166bipr-Input, .select-field .css-1cfo1cf{
        font-size: 11px;
    }
    .select-field .css-13cymwt-control, .select-field .css-t3ipsp-control{
        padding: 6px 4px;
    }
    .select-field .css-1nmdiq5-menu {
        font-size: 12px;
    }
    .content-wrapper.career .field-wrapper{
        max-width: 280px;
    }
    .content-wrapper.career .field-wrapper button{
       right: -70px; 
    }
    .icon-btn span{
        width: 30px;
        font-size: 30px;
        height: 30px;
        margin-right: 10px;
        padding-top: 2px;
    }
    .icon-btn.less span{
        padding-top: 0;
        padding-bottom: 4px;
    }
    .skill-levels-wrapper .skill-levels p .dot, .chart-filter p .dot{
        width: 15px;
        height: 15px;
    }
    .bar-chart-wrapper .filter-wrapper .filter{
        width: 76%;
        margin-bottom: 20px;
    }
    .app-popup.careerDetails .rowMax .col-wrapper{
        flex-direction: column;
        gap: 0;
    }
    .app-popup.careerDetails .rowMax .col-wrapper .col{
        width: 100%;
        margin: 10px 0;
    }
    .content-wrapper .rowMax .popup-tab svg{
        font-size: 54px !important;
    }
    .app-popup.compare-career.careerDetails .MuiPopover-paper{
        padding: 30px 18px;
    }
    .app-popup.careerDetails .row .col, .app-popup.careerDetails .rowMax .col{
        padding: 20px 12px;
    }
    /* .app-popup.careerDetails .row .MuiAccordion-rounded .MuiCollapse-root .highlighted{
        height: unset;
    } */
    .app-popup.careerDetails .row .MuiAccordion-rounded .MuiCollapse-root .highlighted .MuiStack-root span:last-of-type{
        margin-left: 10px;
        width: fit-content;
    }
    .app-popup.careerDetails .row .MuiAccordion-rounded .MuiCollapse-root .highlighted button{
        padding: 4px 6px;
    }
    .app-popup.careerDetails .row .MuiAccordion-rounded .MuiCollapse-root .highlighted button svg{
        width: 20px;
    }
    .app-popup.careerDetails .popup-head{
        flex-direction: column;
        margin-top: 0;
        align-items: flex-start;
    }
    .app-popup.careerDetails .popup-head h3{
        height: fit-content;
    }
    .app-popup.careerDetails .popup-head .MuiBox-root{
        width: 100%;
        align-items: center;
    }
    .app-popup.careerDetails .popup-head .MuiBox-root:last-of-type{
        margin-top: 16px;
    }
    .popup-head .close-career-popup{
        width: 32px;
        height: 32px;
    }
    .app-popup.careerDetails .row .col ul{
        max-height: 296px;
    }
    .app-popup.careerDetails .content-wrapper{
        margin-top: 30px;
    }
    .page-content-wrapper .content .MuiStepper-root .step-icon{
        width: 48px;
        height: 48px;
    }
    .page-content-wrapper .content .MuiStepper-root .step-icon .svg-color{
        width: 36px;
        height: 36px;
    }
    .ai-video-wrapper .laicie-background {
        /* width: 65%; */
        width: 100%;
    }
    .app-popup.compare-career .content-wrapper .table-head-row{
        min-width: 240px !important;
    }
    .app-popup .popup-head.compare{
        flex-direction: column;
        align-items: flex-end;
    }
    .app-popup .popup-head.compare > div{
        justify-content: left;
        width: 100%;
    }
    .app-popup.compare-career .career-popup-head-btn{
        margin-top: 12px;
    }
    .popup-head .close-icon-btn{
        margin-right: 0;
    }
    .app-modal.compare-career .content-wrapper{
        margin-top: 30px;
    }
}

@media only screen and (max-width: 750px){
    .bar-chart .bar-head  .toggle-btn-wrapper .css-lv1wp3-MuiSvgIcon-root{
        height: 0.8em !important;
        width: 0.8em !important;
    }
    .MuiDialog-container .MuiPaper-root.MuiPaper-elevation .css-iqk9mm{
        /* width: 100% !important; */
        width: 70% !important;
    } 
    .close-icon {
        right: 15% !important;
    }
    .bubble-chart img{
        width: 72%;
        left: 27%;
        /* top: 12%; */
    }
    .bubble-chart .close-speech{
        left: 59%;
        top: 52.5%;
        font-size: 0.83rem !important;
    }

    .col.courses-wrapper .css-nztq9p-MuiStack-root {
        flex-direction: column !important;
    }
    .bubble-chart.career-courses{
        transform: translate(0%, 62%) !important;
    }
    .bubble-chart.career-courses img{
        left: 12%;
    }
    .bubble-chart.career-courses .close-speech{
        left: 46%;
    }
    .app-popup.careerDetails .row .MuiAccordion-rounded .MuiCollapse-root .highlighted .MuiStack-root span:last-of-type{
        margin-left: 0px;
        align-items: baseline;
    }
    .col.courses-wrapper .css-nztq9p-MuiStack-root {
        margin-top: 5px;
        margin-bottom: 5px;
        flex-direction: column !important;
    } 
    .css-1h58hwi-MuiTypography-root{
        font-size: 1.35rem;
    }
    .app-popup .popup-head.compare{
        padding: 0 10px;
    }
    .app-modal.compare-career.open .popup-wrapper{
        padding-top: 30px;
    }
    .bar-chart-wrapper .bar-chart .bar-head {
        flex-direction: column !important;
    }
    .bar-chart-wrapper .bar-chart .bar-head .MuiBox-root:last-child{
        margin-top: 10px;
        justify-content: space-between;
        flex-wrap: nowrap;
        width: 100%;
    }
}

@media only screen and (max-width: 599px){
    .MuiContainer-root{
        padding-left: 12px !important;
        padding-right: 12px !important;
    }
    .page-content-wrapper.home .content .entry-text{
        max-width: 94%;
    }
    .chart-popup .popup-chart-wrapper .skilldar-keys > div {
        width: 34%;   
    }
    .css-lv1wp3-MuiSvgIcon-root{
        font-size: 1.4rem;
    }
    .bubble-chart{
        transform: translate(-4%,16%) !important;
    }
    
    .bubble-chart img{
        width: 70%;
        left: 18%;
        top: 4%;
    }
    .bubble-chart .close-speech{
        left: 49%;
        top: 56%;
    }
    .bubble-chart.career-courses{
        transform: translate(0%, 80%) !important;
    }
    .bubble-chart.career-courses img{
        left: 0%;
        width: 100%;
    }
    .bubble-chart.career-courses .close-speech{
        left: 40%;
    }
    .app-popup.careerDetails.mobile{
        display: block;
    }
    .app-popup.careerDetails.dekstop{
        display: none;
    }
    .app-modal.compare-career .content-wrapper .career-row .chart-wrapper{
        min-height: 180px !important;
    }
    .app-popup.compare-career .career-popup-head-btn{
        padding: 3px;
        margin-left: 3px;
    }
    .app-popup.compare-career .career-popup-head-btn svg{
        margin-left: 10px;
    }
    .MuiTypography-root.MuiTypography-h3.css-1h58hwi-MuiTypography-root {
        font-size: 1.25rem;
    }
    .css-1pcqya0-MuiTypography-root{
        font-size:1.3rem !important;
    }
    .page-content-wrapper.home .content img{
        max-width: 340px;
    }
    .logo{
        margin-right: 10px !important;
    }
    .logo img{
        max-width: 94px;
    }
    .bradford-logo-wrapper{
        padding-left: 10px;
        border-width: 1px;
    }
    .bradford-logo-wrapper img{
        max-width: 140px;
    }
    .skillchart-wrapper .chart-wrapper{
        width: 100%;
    }
    .skilldarChart {
        overflow-x: auto;
        overflow-y: hidden;
        display: flex;
        justify-content: center;
        min-height: 460px !important;
    }
    .skilldarChart .apexcharts-canvas{
        width: unset !important;
    }
    .app-popup .popup-head{
        margin-top: 20px;
    }
    .app-popup .popup-head > div{
        margin-right: 0;
    }
    .close-btn{
        top: 16px;
    }
    .select-field .css-13cymwt-control, .select-field .css-t3ipsp-control{
        padding: 4px;
    }
    .app-popup.careerDetails .rowMax .skilldar .skilldarChart{
        min-height: unset !important;
        height: 100% !important;
    }
    .chart-popup .popup-chart-wrapper .MuiGrid-root:first-of-type{
        height: 170px;
    }
    .chart-popup .MuiPaper-root .apexcharts-legend-text{
        max-width: 180px !important;
    }
    .chart-popup .MuiPaper-root{
        padding: 30px 12px;
    }
    .app-popup.skilldar .content-wrapper{
        overflow-x: auto;
    }
    .app-popup.skilldar .slider-content-wrapper {
        min-width: 500px;
    }
    .chart-filter{
        width: 100%;
    }
    .chart-filter p{
        max-width: 162px;
    }
    .chart-wrapper{
        width: 100%;
    }
    .career-chart{
        overflow-y: hidden;
        overflow-x: auto;
        width: 100%;
    }
    .careerChart-wrapper .chart-btn-wrapper .chart-btngrp .toggle-btn-wrapper button{
        min-width: 40px;
    }
    .careerChart-wrapper .chart-btn-wrapper .chart-btngrp button svg{
        font-size: 24px;
    }
    .icon-btn-outlined svg{
        font-size: 24px;
        margin-left: 5px;
    }
    .bar-wrapper .bar > .MuiBox-root span{
        max-width: 60%;
    }
    .bar-wrapper .sub-bar .MuiBox-root:first-of-type{
        max-width: 65%;
    }
    .chart-btn-wrapper .icon-btn-outlined{
        margin-right: 6px !important;
        margin-bottom: 12px;
    }
    .careerChart-wrapper .chart-btn-wrapper .chart-btngrp .toggle-btn-wrapper{
        margin: 0 6px;
        margin-bottom: 12px;
    }
    .careerChart-wrapper .chart-btn-wrapper{
        justify-content: left;
        max-width: 270px;
        align-items: flex-start;
        margin: 0 auto;
    }
    .careerChart-wrapper .chart-btn-wrapper .select-field{
        margin: 0 !important;
    }
    .careerChart-wrapper .chart-btn-wrapper .select-field .MuiSelect-select{
        font-size: 12px;
    }
    .list-view-wrapper{
        overflow: auto !important;
    }
    .list-view-wrapper .list-card{
        width: 440px;
    }
    .chart-filter-wrapper .chart-filter p{
        width: 142px;
    }
    .page-content-wrapper.home .link-wrapper p{
        font-size: 32px;
        margin: 0 6px;
    }
    .page-content-wrapper.home .link-wrapper h1{
        font-size: 32px !important;
    }
    .code-content .box-content{
        padding: 30px 20px;
    }
    .sidebar-nav .MuiPopover-paper{
        width: 280px;
    }
    .content-wrapper.career .field-wrapper{
        max-width: unset;
        width: 70%;
        margin: 12px auto;
    }
    .content-wrapper.career .step-content-wrapper .field-wrapper{
        width: 100%;
    }
    /* .css-lv1wp3-MuiSvgIcon-root{
        font-size: 1.2rem;
    } */
    .content-wrapper.career .field-wrapper button{
        width: fit-content;
        min-width: unset;
        right: -50px;
    }
    .inner-step-wrapper .step-btn svg{
        font-size: 40px;
    }
    .page-content-wrapper .content-wrapper.career{
        padding: 40px 24px;
        padding-top: 50px;
        width: 80% !important;
    }
    .inner-step-wrapper .step-btn{
        width: fit-content;
        min-width: unset;
    }
    .bar-chart-wrapper .bar-chart .bar-head{
        flex-direction: column;
    }
    .bar-chart-wrapper .bar-chart .bar-head .MuiBox-root:last-child{
        margin-top: 10px;
        justify-content: space-between;
        flex-wrap: nowrap;
        width: 100%;
    }
    .bar-chart-wrapper .bar-chart .bar-head .select-field{
        margin: 0;
    }
    .bar-chart-wrapper .bar-chart .bar-head .MuiToggleButtonGroup-root{
        width: 100%;
        justify-content: flex-end;
    }
    .bar-chart-wrapper .filter-wrapper .filter{
        width: 100%;
    }
    .bar-wrapper .sub-bar .MuiBox-root:first-of-type{
        max-width: 65%;
    }
    .app-popup.careerDetails .row .col h5, .app-popup.careerDetails .rowMax .col h5{
        margin-bottom: 8px;
    }
    .app-popup.careerDetails .row .col-wrapper{
        flex-direction: column;
        gap: 0;
    }
    .app-popup.careerDetails .row .col-wrapper .col, .app-popup.careerDetails .row .col-wrapper .MuiStack-root{
        width: 100%;
    }
    .ai-video-wrapper .laicie-background {
        width: 100%;
    }
    .bar-chart-wrapper .filter-wrapper .MuiStack-root > .MuiBox-root{
        padding: 16px 0 !important;
    }
    .bar-chart-wrapper .filter-wrapper .selected-career-wrapper .MuiStack-root > .MuiBox-root{
        padding: 0 !important;
    }
    .app-modal.open.careerDetails .popup-wrapper{
        padding: 30px 16px;
    }
    .app-modal.careerDetails.open .popup-wrapper, .app-modal.compare-career.open .popup-wrapper{
        width: 100%;
    }
    .app-modal.compare-career.open .popup-wrapper{
        padding-top: 10px;
    }
    .app-modal.compare-career .content-wrapper .career-row{
        min-width: 180px !important;
        max-width: unset !important;
    }
    .app-modal.compare-career .content-wrapper .table-head-row .compare-data{
        padding-left: 16px !important;
    }
    .content-wrapper .table-head-row .compare-skill{
        padding-left: 16px;
    }
    .app-popup.compare-career .content-wrapper .table-head-row{
        min-width: 230px !important;
    }
    .app-popup.skilldar .MuiPopover-paper{
        width: 90% !important;
        left: 5% !important;
    }
    .chart-popup .MuiPaper-root{
        width: 100%;
        transform: translateX(50%) !important;
        right: 50%;
    }

    .btn-wrapper.career-history{
        margin-top: 36px;
    }
    .content-wrapper .table-head-row .compare-skill{
        height: 180px;
    }
    .app-modal.compare-career .chart-wrapper .skilldarChart{
        height: 180px;
        overflow: hidden;
        min-height: unset !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .app-modal.compare-career .content-wrapper .career-row .content > div:last-of-type{
        padding-bottom: 20px;
    }
    .app-popup.compare-career .content-wrapper .table-head-row > div:last-of-type{
        padding-bottom: 18px;
    }

}
@media only screen and (max-width: 399px){
    .career-responsive-btns.MuiBox-root{
        margin-top: 5px;
    }
    .close-icon {
        right: 4% !important;
    }
    .MuiDialog-container .MuiPaper-root.MuiPaper-elevation .css-iqk9mm{
        /* width: 100% !important; */
        width: 90% !important;
    } 
    .bubble-chart img{
        width: 48%;
        left: 30%;
        /* top: 11%; */
    }
    .bubble-chart .close-speech{
        left: 48%;
        /* top: 52%; */
        font-size: 0.7rem !important;
    }
    .bubble-chart.career-courses{
        transform: translate(0%, 80%) !important;
    }
    .css-1hi9ewh{
        flex-direction: column !important;
    }
    .css-1cyvk1j-MuiTypography-root{
        font-size: 0.575rem !important;
    }
    
    .app-popup.compare-career .career-popup-head-btn{
        margin-left: 0px;
        flex: auto;
    }
    .app-popup.careerDetails .popup-head .MuiBox-root:last-of-type{
        flex-direction:row;
    }
    .popup-wrapper .popup-head .css-14ztewy-MuiButtonBase-root-MuiButton-root{
        width: 100%;
        margin-left: 3px;
    }
    .logo img{
        max-width: unset;
        width: 100%;
    }
    .sidebar-nav .sidebar-wrapper .clg-logo{
        max-height: 80px;
    }
    .sidebar-nav .MuiPopover-paper{
        width: 240px;
    }
    .css-1h58hwi-MuiTypography-root {
        font-size:18px !important;
    }
    .css-ppz5vs-MuiTypography-root {
        font-size: 1rem !important;
    }
    .app-popup.careerDetails .row .MuiAccordion-rounded .MuiCollapse-root .highlighted button {
        padding: 0px 6px !important;
        flex: 1;
    }
    .content-wrapper .rowMax .tabs-size{
        font-size: 0.8rem !important;
    }
    .chart-popup .skilldarChart {
        min-height: 400px !important;
    }
}
@media only screen and (max-width:360) {
    .css-6dgw85-MuiTypography-root{
        font-size: 0.55rem;
    }
    .app-popup.compare-career .career-popup-head-btn{
        padding: 5px 5px;
    }
    /* .app-popup.careerDetails .row .MuiAccordion-rounded .MuiCollapse-root .highlighted button{
        padding: 5px 1px !important;
    } */
    /* .css-13dy2c6{
        height: 50px;
    } */
    /* .css-1hi9ewh{
        flex-direction: column;
    } */
    
}

.css-129z8mp-MuiTypography-root a{
    text-decoration: underline;
    margin-left: 5px;
    text-underline-offset:3px !important;
    color: #000c39;

}

.popup-keys{
    display: flex;
    flex-direction: column;
}
.skilldar-keys{
    height: 25%;
    width: 20%;
    padding-left: 20px;
    display: flex;
    align-items: center;
    background-color:#f4f4f4 !important;
    margin-bottom: 4px;
    margin-left: 10px;
    z-index: 2;
    position: absolute;
    border-radius: 20px;
    /* margin-top: 3px; */
}
.skilldar-keys .line{
    margin-right: 10px;
    border: 1px solid #8a1e1e;
}
.skilldar-keys .skill-level{
    text-align: left;
}
.skilldar-keys .title{
    font-size: 0.8rem ;
}
.skilldar-keys .skill-level {
    font-size: 0.7rem;
}
.skill-levels-wrapper .skill-level svg{
    height: 1.2em !important;
    width: 1.2em !important;
    border-radius: 50% !important;
}
